import React from "react";

import {
  SolarPanelFilters,
  SwitchFilters,
  InvertorFilters,
  BatteryFilters,
  AutowireFilters
} from "components";

const FiltersComponent = (props) => {
  const { name } = props.match.params;

  return (
    <div>
      {name === 'solar_panel' && <SolarPanelFilters />}
      {name === 'switch' && <SwitchFilters />}
      {name === 'battery' && <BatteryFilters />}
      {name === 'invertor' && <InvertorFilters />}
      {name === 'autowire' && <AutowireFilters />}
    </div>
  );
}

export default FiltersComponent;
