import React, { Component } from "react";

export default class NavIcon extends Component {
  render() {
    return (
      <div
        className="navIcon"
        style={{ width: 100 / this.props.numberOfIcons + "vw" }}
      >
        <div>
          <img src={this.props.src} alt="NavIcon"/>
        </div>
      </div>
    );
  }
}
