import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { SetAutowireFilters } from "store/actions";
import { AutowireActive as headerImg } from "assets/images";

const AutowireFilters = (props) => {
  const history = useHistory();
  const [ wiring, setWiring ] = useState(props.autowires.wiring || '');

  const applyFilters = (e) => {
    e.preventDefault();
    props.bindFilters({
      wiring
    });
    goBack();
  }

  const goBack = () => {
    history.push("/app/product/autowire");
  }

  return (
    <div className="pb-150">
      <div className="d-flex justify-content-between align-items-center p-2 bg-primary">
        <div className="p-2 pointer" onClick={e => goBack()} title="Cancel">
          <FontAwesomeIcon icon={faArrowLeft} className="font-large text-white" />
        </div>
        <div className="p-1">
          <img src={headerImg} alt="Invertor"/>
        </div>
        <div className="p-2 pointer" onClick={e => applyFilters(e)} title="Apply">
          <FontAwesomeIcon icon={faCheck} className="font-large text-white" />
        </div>
      </div>
      <div className="pt-4 px-3 font-larger">
        Autowire
        <div className="pull-right text-primary">
          {
            (wiring === 'yes') ? <FontAwesomeIcon icon={faCheckSquare} className="font-x-large pointer" onClick={e => setWiring('no')} /> : <input type="checkbox" className="custom-checkbox pointer" onClick={e => setWiring('yes')} />
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({virtual_building}) => {
  const { autowires } = virtual_building;
  return { autowires };
}

const mapDispatchToProps = (dispatch) => {
  return {
    bindFilters: (filters) => dispatch(SetAutowireFilters(filters))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutowireFilters);
