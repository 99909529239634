import React from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

const Profile = (props) => {
  const history = useHistory();

  const logout = () => {
    history.push('/login');
  }

  return (
    <div className="arrays-wrapper">
      <div className="d-flex justify-content-between align-items-center p-2 bg-primary text-white">
        <div className="p-2 pointer" title="Cancel">
          Welcome Naga
        </div>
        <div className="p-2 pointer" title="Logout">
          <FontAwesomeIcon icon={faSignOutAlt} className="font-large" onClick={e => logout()}/>
        </div>
      </div>
    </div>
  )
}

export default Profile;
