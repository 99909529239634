import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { SetBuildingItem } from "store/actions";
import { Products as products } from "constants/products";
import { EditActive } from "assets/images";

const RoofTypeComponent = (props) => {
  const history = useHistory();
  const [ selectedItem, setSelectedItem ] = useState(props.products["roof_type"] || '');

  const goBack = () => {
    history.push({
      pathname: "/app/virtual_building",
      state: { tab: "roof" }
    });
  }

  const handleItem = (item, axis, value) => {
    setSelectedItem({
      ...item,
      [axis]: [value]
    });
  }

  const saveDetails = () => {
    if (!selectedItem) {
      alert(`Please select a roof type.`);
      return;
    }
    if (!selectedItem.x) {
      alert(`Please enter length dimension.`);
      return;
    }
    if (selectedItem === "Rectangle" && !selectedItem.y) {
      alert(`Please enter breadth dimension.`);
      return;
    }
    props.bindBuildingItem({
      key: "roof_type",
      value: {
        ...selectedItem,
        x: +selectedItem['x'],
        y: +selectedItem['y']
      }
    })
    goBack()
  }

  return (
    <div className="pb-150 product-page">
      <nav className="d-flex fixed-top justify-content-between align-items-center p-2 bg-primary">
        <div className="p-2 pointer" onClick={e => goBack()} title="Cancel">
          <FontAwesomeIcon icon={faArrowLeft} className="font-large text-white" />
        </div>
        <div className="p-1">
          <img src={EditActive} alt="Edit"/>
        </div>
        <div className="p-1">
        </div>
      </nav>
      <div className="container mt-5 pt-3">
        <div className="sub-header">
          Select a roof type
          <button className="btn btn-orange-outline btn-sm float-right" onClick={saveDetails}>
            Next
          </button>
        </div>
        <div className="row items-list">
          {products["roof_type"].map((item, idx) => (
            <div className="card item-details col-6" key={idx}>
              <img src={require(`assets/images/products/${item.image}`).default} alt={item.title} />
              <div className="card-body text-center">
                <div>
                  <input type="radio" id={`item_roof_type_"${idx}`} name="roof_type" value={item.title} onChange={e => handleItem(item)} checked={item.title=== (selectedItem&&selectedItem.title)} /> &nbsp;
                  <label htmlFor={`item_roof_type_"${idx}`} className="form-label">{item.title}</label>
                </div>
              </div>
            </div>
          ))}
        </div>
        {
          selectedItem.title === "Rectangle" &&
          <div className="form-row">
              <div className="col">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">m</div>
                  </div>
                  <input type="number" className="form-control" placeholder="Length" onChange={e => handleItem(selectedItem, "x", e.target.value)} value={selectedItem.x || ""}/>
                </div>
              </div>
              <div className="col">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">m</div>
                  </div>
                  <input type="number" className="form-control" placeholder="Breadth" onChange={e => handleItem(selectedItem, "y", e.target.value)} value={selectedItem.y || ""}/>
                </div>
              </div>
          </div>
        }
        {
          selectedItem.title === "Square" &&
          <div className="form-row">
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">m</div>
              </div>
              <input type="number" className="form-control" placeholder="Length" onChange={e => handleItem(selectedItem, "x", e.target.value)} value={selectedItem.x || ""}/>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

const mapStateToProps = ({virtual_building}) => {
  const { products, filters } = virtual_building;
  return { products, filters };
}

const mapDispatchToProps = (dispatch) => {
  return {
    bindBuildingItem: (item) => dispatch(SetBuildingItem(item))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoofTypeComponent);
