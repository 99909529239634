import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { SetPanelFilters } from "store/actions";
import { SolarPanelActive as headerImg } from "assets/images";

const SolarPanelFilters = (props) => {
  const history = useHistory();
  const [ watts, setWatts ] = useState(props.solar_panel.watts || '');
  const [ efficiency, setEfficiency ] = useState(props.solar_panel.efficiency || '');
  const [ price, setPrice ] = useState(props.solar_panel.price || '');

  const applyFilters = (e) => {
    e.preventDefault();
    props.bindFilters({
      watts,
      efficiency,
      price
    });
    goBack();
  }

  const goBack = () => {
    history.push("/app/product/solar_panel");
  }

  const resetFilters = () => {
    setWatts('');
    setEfficiency('');
    setPrice('');
  }

  return (
    <div className="pb-150">
      <nav className="d-flex fixed-top justify-content-between align-items-center p-2 bg-primary">
        <div className="p-2 pointer" onClick={e => goBack()} title="Cancel">
          <FontAwesomeIcon icon={faArrowLeft} className="font-large text-white" />
        </div>
        <div className="p-1">
          <img src={headerImg} alt="Panel"/>
        </div>
        <div className="p-2 pointer" onClick={e => applyFilters(e)} title="Apply">
          <FontAwesomeIcon icon={faCheck} className="font-large text-white" />
        </div>
      </nav>
      <div className="container mt-5 pt-4">
        <div className="pt-3 px-1">
          <div className="form-group">
            <label htmlFor="watts-dropdown">Watts (in W)</label>
            <select className="form-control" id="watts-dropdown" onChange={e => setWatts(e.target.value)} value={watts}>
              <option>Choose</option>
              <option value="301-350">301-350</option>
              <option value="351-400">351-400</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="efficiency-dropdown">Efficiency (in %)</label>
            <select className="form-control" id="efficiency-dropdown" onChange={e => setEfficiency(e.target.value)} value={efficiency}>
              <option>Choose</option>
              <option value="20-21">20-21</option>
              <option value="21-22">21-22</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="price-dropdown">Price (in £)</label>
            <select className="form-control" id="price-dropdown" onChange={e => setPrice(e.target.value)} value={price}>
              <option>Choose</option>
              <option value="150-200">150-200</option>
              <option value="200-250">200-250</option>
              <option value="250-300">250-300</option>
            </select>
          </div>
        </div>
        <div className="text-center mt-4">
          <button className="btn btn-sm btn-outline-primary" onClick={e => resetFilters()}>
            Reset &nbsp; <FontAwesomeIcon icon={faSync} title="Reset" />
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({virtual_building}) => {
  const { solar_panel } = virtual_building.filters;
  return { solar_panel };
}

const mapDispatchToProps = (dispatch) => {
  return {
    bindFilters: (filters) => dispatch(SetPanelFilters(filters))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolarPanelFilters);
