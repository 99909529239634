import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'

const Register = (props) => {
  const history = useHistory();
  const [ terms, setTerms ] = useState("");

  const redirectTo = (pathname) => {
    history.push(pathname);
  }

  return (
    <div className="login-wrapper" style={{maxWidth: "500px", margin: "0px auto"}}>
      <div className="d-flex justify-content-between align-items-center p-2 bg-primary">
        <div className="p-2">
          Register
        </div>
      </div>
      <div className="container py-3">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" className="form-control" id="email" placeholder="Ex: nich@gmail.com" />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input type="password" className="form-control" id="password" />
        </div>
        <div className="form-group">
          <label htmlFor="password">Confirm Password</label>
          <input type="password" className="form-control" id="password" />
        </div>
        <div className="text-primary">
          {
            (terms === 'yes') ? <FontAwesomeIcon icon={faCheckSquare} className="font-x-large pointer" onClick={e => setTerms('no')} /> : <input type="checkbox" className="custom-checkbox pointer" onClick={e => setTerms('yes')} />
          }
          <span className="ml-2"> Agree to our <u>Terms of service</u> and <u>Privacy policy</u> </span>
        </div>
        <button type="submit" className="btn btn-outline-primary btn-md mt-3" onClick={e => redirectTo('login')}>Submit</button> <br/><br/>
        <button type="" className="btn btn-link text-primary" onClick={e => redirectTo('login')}>Already a user?</button><br/>
      </div>
    </div>
  )
}

export default Register;
