import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { SetInvertorFilters } from "store/actions";
import { InvertorActive as headerImg } from "assets/images";

const InvertorFilters = (props) => {
  const history = useHistory();
  const [ maxVolts, setMaxVolts ] = useState(props.invertor.maxVolts || '');
  const [ minVolts, setMinVolts ] = useState(props.invertor.minVolts || '');
  const [ price, setPrice ] = useState(props.invertor.price || '');

  const applyFilters = (e) => {
    e.preventDefault();
    props.bindFilters({
      maxVolts,
      minVolts,
      price
    });
    goBack();
  }

  const goBack = () => {
    history.push("/app/product/invertor");
  }

  const resetFilters = () => {
    setMaxVolts('');
    setMinVolts('');
    setPrice('');
  }

  return (
    <div className="pb-150">
      <nav className="d-flex fixed-top justify-content-between align-items-center p-2 bg-primary">
        <div className="p-2 pointer" onClick={e => goBack()} title="Cancel">
          <FontAwesomeIcon icon={faArrowLeft} className="font-large text-white" />
        </div>
        <div className="p-1">
          <img src={headerImg} alt="Invertor"/>
        </div>
        <div className="p-2 pointer" onClick={e => applyFilters(e)} title="Apply">
          <FontAwesomeIcon icon={faCheck} className="font-large text-white" />
        </div>
      </nav>
      <div className="container mt-5 pt-4">
        <div className="pt-3 px-1">
          <div className="form-group">
            <label htmlFor="max-volts-dropdown">Max Volts (in V)</label>
            <select className="form-control" id="max-volts-dropdown" onChange={e => setMaxVolts(e.target.value)} value={maxVolts}>
              <option>Choose</option>
              <option value="325">325</option>
              <option value="335">335</option>
              <option value="340">340</option>
              <option value="355">355</option>
              <option value="365">365</option>
              <option value="370">370</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="min-volts-dropdown">Min Volts (in V)</label>
            <select className="form-control" id="min-volts-dropdown" onChange={e => setMinVolts(e.target.value)} value={minVolts}>
              <option>Choose</option>
              <option value="325">325</option>
              <option value="335">335</option>
              <option value="340">340</option>
              <option value="355">355</option>
              <option value="365">365</option>
              <option value="370">370</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="price-dropdown">Price (in £)</label>
            <select className="form-control" id="price-dropdown" onChange={e => setPrice(e.target.value)} value={price}>
              <option>Choose</option>
              <option value="150-200">150-200</option>
              <option value="200-250">200-250</option>
              <option value="250-300">250-300</option>
            </select>
          </div>
        </div>
        <div className="text-center mt-4">
          <button className="btn btn-sm btn-outline-primary" onClick={e => resetFilters()}>
            Reset &nbsp; <FontAwesomeIcon icon={faSync} title="Reset" />
          </button>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = ({virtual_building}) => {
  const { invertor } = virtual_building.filters;
  return { invertor };
}

const mapDispatchToProps = (dispatch) => {
  return {
    bindFilters: (filters) => dispatch(SetInvertorFilters(filters))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvertorFilters);
