import React from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const Cart = (props) => {
  const history = useHistory();

  const gotoProfile = () => {
    history.push('/profile');
  }

  return (
    <div className="arrays-wrapper">
      <div className="d-flex justify-content-between align-items-center p-2 bg-primary">
        <div className="p-2 pointer" title="Cancel">
          Cart
        </div>
        <div className="p-2 pointer" title="Apply">
          <FontAwesomeIcon icon={faUser} className="font-large text-white" onClick={e => gotoProfile()} />
        </div>
      </div>
    </div>
  )
}

export default Cart;
