import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { Toolbox as toolbox } from "constants/toolbox";

const ToolboxItems = (props) => {
  const history = useHistory();

  const onClick = (title) => {
    props.onClick(title);
    /* Select SubMenu */
    let selectedItem = toolbox.find(item => item.title === title);
    if (selectedItem.route) {
      history.push(`/app/product/${selectedItem.route}`);
    }
  }

  const loadImage = (img) => {
    return <img src={require(`assets/images/products/${img}`).default} alt={img} width="50" />
  }

  return (
    <div className="toolbox-items-row mt-2">
      { toolbox &&
        toolbox.map(item => item.tab === props.displayTab && (
          <div key={item.title}>
            <button className={`btn btn-sm btn-orange-outline mx-2 ${props.title === item.title && "active"}`} title={item.title} onClick={e => onClick(item.title)}>
              <img src={item.svg} id={item.title} alt={item.title}/>
            </button>
          </div>
        ))
      }
      <div className="selected-products">
        <div className="selected-products-list">
          {
            Object.keys(props.products).map((key, idx) => {
              if (key !== "roof_type") {
                return props.products[key].quantity &&
                  [...Array(props.products[key].quantity)].map((e, childIdx) => (
                    <div key={`${idx}_${childIdx}`} className="product">
                      {loadImage(props.products[key].image)}
                    </div>
                  ))
              } else return key;
            })
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({virtual_building}) => {
  const { products } = virtual_building;
  return { products };
}

export default connect(
  mapStateToProps,
  null
)(ToolboxItems);
