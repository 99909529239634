import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { SetBatteryFilters } from "store/actions";
import { BatteryActive as headerImg } from "assets/images";

const BatteryFilters = (props) => {
  const history = useHistory();
  const [ maxAmps, setMaxAmps ] = useState(props.battery.maxAmps || '');
  const [ type, setType ] = useState(props.battery.type || '');
  const [ price, setPrice ] = useState(props.battery.price || '');

  const applyFilters = (e) => {
    e.preventDefault();
    props.bindFilters({
      maxAmps,
      type,
      price
    });
    goBack();
  }

  const goBack = () => {
    history.push("/app/product/battery");
  }

  const resetFilters = () => {
    setMaxAmps('');
    setType('');
    setPrice('');
  }

  return (
    <div className="pb-150">
      <nav className="d-flex fixed-top justify-content-between align-items-center p-2 bg-primary">
        <div className="p-2 pointer" onClick={e => goBack()} title="Cancel">
          <FontAwesomeIcon icon={faArrowLeft} className="font-large text-white" />
        </div>
        <div className="p-1">
          <img src={headerImg} alt="Battery"/>
        </div>
        <div className="p-2 pointer" onClick={e => applyFilters(e)} title="Apply">
          <FontAwesomeIcon icon={faCheck} className="font-large text-white" />
        </div>
      </nav>
      <div className="container mt-5 pt-4">
        <div className="pt-3 px-1">
          <div className="form-group">
            <label htmlFor="max-amps-dropdown">Max Amp/h</label>
            <select className="form-control" id="max-amps-dropdown" onChange={e => setMaxAmps(e.target.value)} value={maxAmps}>
              <option>Choose</option>
              <option value="2.4">2.4</option>
              <option value="3.5">3.5</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="price-dropdown">Price (in £)</label>
            <select className="form-control" id="price-dropdown" onChange={e => setPrice(e.target.value)} value={price}>
              <option>Choose</option>
              <option value="600-700">600-700</option>
              <option value="1000-1100">1000-1100</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="type-dropdown">Type</label>
            <select className="form-control" id="type-dropdown" onChange={e => setType(e.target.value)} value={type}>
              <option>Choose</option>
              <option value="325">325</option>
              <option value="335">335</option>
              <option value="340">340</option>
              <option value="355">355</option>
              <option value="365">365</option>
              <option value="370">370</option>
            </select>
          </div>
        </div>
        <div className="text-center mt-4">
          <button className="btn btn-sm btn-outline-primary" onClick={e => resetFilters()}>
            Reset &nbsp; <FontAwesomeIcon icon={faSync} title="Reset" />
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({virtual_building}) => {
  const { battery } = virtual_building.filters;
  return { battery };
}

const mapDispatchToProps = (dispatch) => {
  return {
    bindFilters: (filters) => dispatch(SetBatteryFilters(filters))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatteryFilters);
