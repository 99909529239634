import {
  SET_PANEL_FILTERS,
  SET_BATTERY_FILTERS,
  SET_INVERTOR_FILTERS,
  SET_SWITCH_FILTERS,
  SET_BUILDING_ITEM
} from 'store/types';

const INITIAL_STATE = {
  filters: {
    solar_panel: {},
    battery: {},
    switchy: {},
    invertor: {}
  },
  products: {
    solar_panel: {},
    battery: {},
    switchy: {},
    invertor: {}
  }
}

const VirtualBuilding = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_BUILDING_ITEM:
      return {
        ...state,
        products: {
          ...state.products,
          [payload.key]: payload.value
        }
      }
    case SET_PANEL_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          solar_panel: payload.filters
        }
      }
    case SET_BATTERY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          battery: payload.filters
        }
      }
    case SET_INVERTOR_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          invertor: payload.filters
        }
      }
    case SET_SWITCH_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          switch: payload.filters
        }
      }
    default:
      return state;
  }
}

export default VirtualBuilding;
