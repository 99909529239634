import {
  Edit,
  SolarPanel,
  Battery,
  Invertor,
  Switches,
  Autowire,
  EditActive,
  SolarPanelActive,
  BatteryActive,
  InvertorActive,
  SwitchesActive
} from "assets/images";

export const Toolbox = [
  {
    title: "Edit",
    route: "roof_type",
    svg: Edit,
    svg_active: EditActive,
    tab: 'roof'
  },
  {
    title: "SolarPanel",
    route: "solar_panel",
    svg: SolarPanel,
    svg_active: SolarPanelActive,
    tab: 'roof'
  },
  {
    title: "Switches",
    route: "switchy",
    svg: Switches,
    svg_active: SwitchesActive,
    tab: 'connection'
  },
  {
    title: "Battery",
    route: "battery",
    svg: Battery,
    svg_active: BatteryActive,
    tab: 'connection'
  },
  {
    title: "Invertor",
    route: "invertor",
    svg: Invertor,
    svg_active: InvertorActive,
    tab: 'connection'
  },
  {
    title: "Autowire",
    svg: Autowire,
    tab: 'connection'
  }
]
