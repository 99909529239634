import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Wrench, SolarPanel, Cart, Helmet } from "assets/images";

const BottomNav = (props) => {
  const location = useLocation();
  return (
      <nav className="d-flex fixed-bottom flex-row navbar-bottom align-items-center align-self-center justify-content-around bottom-nav">
        <div className="nav-item">
          <div className="nav-link text-center">
            <Link to="/app/virtual_building">
              <img src={Wrench} className="wrench" alt="wrench"/><br/>
              <span className={`active ${!location.pathname.includes('/app/virtual_building') && 'd-none'}`}>&middot;</span>
            </Link>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-link text-center">
            <Link to="/app/arrays">
              <img src={SolarPanel} className="panel" alt="panel"/> <br/>
              <span className={`active ${location.pathname !== '/app/arrays' && 'd-none'}`}>&middot;</span>
            </Link>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-link text-center">
            <Link to="/app/cart">
              <img src={Cart} className="cart" alt="cart"/><br/>
              <span className={`active ${location.pathname !== '/app/cart' && 'd-none'}`}>&middot;</span>
            </Link>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-link text-center">
            <Link to="/app/network">
              <img src={Helmet} className="helmet" alt="helmet"/><br/>
              <span className={`active ${location.pathname !== '/app/network' && 'd-none'}`}>&middot;</span>
            </Link>
          </div>
        </div>
      </nav>
    );
}

export default BottomNav;
