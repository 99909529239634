import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faArrowLeft, faFilter, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import { SetBuildingItem } from "store/actions";
import { Products as products } from "constants/products";
import { Toolbox as toolbox } from "constants/toolbox";
import RoofTypeComponent from "./RoofType";

const ProductsComponent = (props) => {
  const history = useHistory();
  const { name } = props.match.params;
  const [ headerImage, setHeaderImage ] = useState('');
  const [ filterStatus, setFilterStatus ] = useState(false);
  const [ items, setItems ] = useState([]);
  const [ selectedItem, setSelectedItem ] = useState(props.products[name] || '');
  const [ itemCount, setItemCount ] = useState((props.products[name] && props.products[name].quantity) || 1);

  useEffect(() => {
    function filterItems() {
      let filter = props.filters[name];
      let dup_items = products[name];
      for (let key in filter) {
        if (filter[key].includes("-")) {
          let vals = filter[key].split("-");
          dup_items = dup_items.filter(i => +vals[0] <= i[key] && i[key] < +vals[1]);
        } else if (filter[key]) {
          dup_items = dup_items.filter(i => i[key] < +filter[key]);
        }
      }
      setHeaderImage(toolbox.find(t => t.route === name).svg_active);
      setItems(dup_items);
      setFilterStatus(true);
    }

    if (!filterStatus) {
      filterItems();
    }
  }, [props, filterStatus, name])

  const redirectToFilters = () => {
    history.push(`/app/${name}/filter`);
  }

  const goBack = () => {
    history.push({
      pathname: "/app/virtual_building",
      state: { tab: toolbox.find(t => t.route === name).tab }
    });
  }

  const handleItem = (item) => {
    setSelectedItem(item);
  }

  const saveDetails = () => {
    if (!selectedItem) {
      alert(`Please select a ${name.replace("_", " ")} before moving on to the next step.`);
      return;
    }
    if (itemCount < 1) {
      alert('A minimum of one item is required.');
      return;
    }
    props.bindBuildingItem({
      key: name,
      value: {
        ...selectedItem,
        quantity: itemCount
      }
    })
    goBack()
  }

  return name === "roof_type" ?
    <RoofTypeComponent props={props} />
    : (
      <div className="pb-150 product-page">
        <nav className="d-flex fixed-top justify-content-between align-items-center p-2 bg-primary">
          <div className="p-2 pointer" onClick={e => goBack()} title="Cancel">
            <FontAwesomeIcon icon={faArrowLeft} className="font-large text-white" />
          </div>
          <div className="p-1">
            <img src={headerImage} alt={name}/>
          </div>
          <div className="p-2 pointer" onClick={e => redirectToFilters(e)} title="Apply">
            <FontAwesomeIcon icon={faFilter} className="font-large text-white" />
          </div>
        </nav>
        <div className="container mt-5 pt-3">
          <div className="sub-header">
            Select a {name.replace("_", " ")}
            <button className="btn btn-orange-outline btn-sm float-right" onClick={saveDetails}>
              Next
            </button>
          </div>
          <div className="row items-list">
            {items && items.map((item, idx) => (
              <div className="card item-details col-6" key={idx}>
                <img src={require(`assets/images/products/${item.image}`).default} alt={item.title} />
                <div className="card-body text-center">
                  <div>
                    <input type="radio" id={`item_${name}_${idx}`} name={name} value={item.title} onChange={e => handleItem(item)} checked={item.title=== (selectedItem&&selectedItem.title)} /> &nbsp;
                    <label htmlFor={`item_${name}_${idx}`} className="form-label">{item.title}</label>
                  </div>
                  <div className={`input-group input-group-sm mb-3 ${ item.title !== selectedItem.title && "d-none"}`}>
                    <div className="input-group-prepend">
                      <button className="btn btn-orange" id="button-addon" onClick={e => setItemCount(+itemCount-1)} disabled={itemCount <= 1}>
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    </div>
                    <input type="number" className="form-control" aria-label="Amount (to the nearest dollar)" value={itemCount} onChange={e => setItemCount(e.target.value)} min="1"/>
                    <div className="input-group-append">
                      <button className="btn btn-orange" id="button-addon" onClick={e => setItemCount(+itemCount+1)}>
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {
            !items[0] && <div className="text-center mt-5">No items found</div>
          }
        </div>
      </div>
    );
}

const mapStateToProps = ({virtual_building}) => {
  const { products, filters } = virtual_building;
  return { products, filters };
}

const mapDispatchToProps = (dispatch) => {
  return {
    bindBuildingItem: (item) => dispatch(SetBuildingItem(item))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsComponent);
