import React from "react";
import { BottomNav } from "components";

const DefaultLayout = ({children}) => {
  return (
    <div style={{maxWidth: "500px", margin: "0px auto"}}>
      {children}
      <BottomNav/>
    </div>
  );
}

export default DefaultLayout;
