export const Products = {
  roof_type: [
    {
      title: 'Rectangle',
      area: '10x10mm',
      image: 'lg_neon_r.jpeg'
    },
    {
      title: 'Square',
      area: '10x10mm',
      image: 'lg_neon_r.jpeg'
    }
  ],
  solar_panel: [
    {
      title: 'LG neon R',
      watts: 380,
      wattsUnit: 'W',
      efficiency: 22.00,
      efficiencyUnit: 'Percentage',
      price: 394.46,
      priceUnit: 'GBP',
      image: 'lg_neon_r.jpeg'
    },
    {
      title: 'LG neon 2',
      watts: 355,
      wattsUnit: 'W',
      efficiency: 20.60,
      efficiencyUnit: 'Percentage',
      price: 280.50,
      priceUnit: 'GBP',
      image: 'lg_neon_2.jpeg'
    },
    {
      title: 'Clone R1',
      watts: 320,
      wattsUnit: 'W',
      efficiency: 22.00,
      efficiencyUnit: 'Percentage',
      price: 394.46,
      priceUnit: 'GBP',
      image: 'lg_neon_r.jpeg'
    },
    {
      title: 'Clone 21',
      watts: 315,
      wattsUnit: 'W',
      efficiency: 20.60,
      efficiencyUnit: 'Percentage',
      price: 280.50,
      priceUnit: 'GBP',
      image: 'lg_neon_2.jpeg'
    }
  ],
  invertor: [
    {
      title: 'Solis 4kW',
      watts: 4,
      wattsUnit: 'kW',
      price: 403.82,
      priceUnit: 'GBP',
      image: 'solis_6kw.png'
    },
    {
      title: 'Solis 6kW',
      watts: 6,
      wattsUnit: 'kW',
      price: 609.60,
      priceUnit: 'GBP',
      image: 'solis_6kw.png'
    },
    {
      title: 'Iconica',
      watts: 2,
      wattsUnit: 'kW',
      price: 299.99,
      priceUnit: 'GBP',
      image: 'iconica_2kw.png'
    }
  ],
  switchy: [
    {
      title: 'DC Isolator',
      volts: 1000,
      amps: '32',
      price: 29.90,
      priceUnit: 'GBP',
      image: 'dc_isolator_1.jpeg'
    },
    {
      title: 'AC Isolator',
      amps: '25/32',
      price: 14.90,
      priceUnit: 'GBP',
      image: 'ac_isolator_1.jpeg'
    }
  ],
  battery: [
    {
      title: 'PyIontech',
      watts: 2.4,
      wattsUnit: 'kW',
      price: 710,
      priceUnit: 'GBP',
      image: 'pyiontech_2_4kw.jpeg'
    }
  ]
}
