import React, { Component } from "react";
import { Link } from "react-router-dom";

import { ToolboxItems } from "components";
import { Toolbox, ToolboxActive, Play, Pin, Grid } from "assets/images";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const active = "building";

class VirtualBuilding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submenu: "toolbox",
      title: "",
      displayTab: (props.location.state && props.location.state.tab) || 'roof'
    }
  }

  componentDidMount() {
    this.zoomInOut();
  }

  displayContent = (name) => {
    this.setState({
      title: name
    })
  }

  zoomInOut = () => {
    let gridImg = document.getElementById("gridImg");
    let currentWidth = gridImg.clientWidth;
    if (currentWidth === 2500) {
      gridImg.style.width = "100vw";
    } else {
      gridImg.style.width = "2500px";
    }
  }

  render() {
    return (
      <div className="virtual-building-wrapper">
        <div className="page-header">
          <div className="d-flex flex-row align-items-center align-self-center justify-content-around">
            <div className="nav-item">
              <div className="nav-link text-center pointer">
                {this.state.submenu === "toolbox" ?
                <div className="toolbox-active" onClick={e => this.setState({submenu: ""})}>
                  <img src={ToolboxActive} className="toolbox-icon" alt="ToolboxActiveImg"/>
                </div>
                : <img src={Toolbox} className="toolbox-icon" onClick={e => this.setState({submenu: "toolbox"})} alt="ToolboxImg"/>
                }
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link text-center">
                <Link to="/arrays">
                  <img src={Play} className="panel" alt="PlayImg"/> <br/>
                  <span className={`active ${active !== '/arrays' && 'd-none'}`}>&middot;</span>
                </Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link text-center">
                <Link to="/cart">
                  <img src={Pin} className="cart" alt="PinImg"/><br/>
                  <span className={`active ${active !== '/cart' && 'd-none'}`}>&middot;</span>
                </Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link text-center pr-0">
                <Link to="/profile">
                  <FontAwesomeIcon icon={faUser} className="font-x-large text-primary" />
                  { /*
                    <img src={Menu} className="helmet" alt="MenuImg"/><br/>
                    <span className={`active ${active !== '/network' && 'd-none'}`}>&middot;</span>
                  */}
                </Link>
              </div>
            </div>
          </div>
          {this.state.submenu === "toolbox" &&
            <div className='row'>
              <button className='col-6 btn btn-sm btn-orange' onClick={e => this.setState({displayTab: 'roof'})}>Roof</button>
              <button className='col-6 btn btn-sm btn-orange' onClick={e => this.setState({displayTab: 'connection'})}>Connection</button>
            </div>
          }
        </div>
        { this.state.submenu === "toolbox" && <ToolboxItems title={this.state.title} displayTab={this.state.displayTab} onClick={this.displayContent} />}
        {/* Grid image */}
        <div style={{overflowX: "scroll",}}>
          <img src={Grid} id="gridImg" onClick={this.zoomInOut} alt="GridImg"/>
        </div>
      </div>
    );
  }
}

export default VirtualBuilding;
