import {
  SET_PANEL_FILTERS,
  SET_BATTERY_FILTERS,
  SET_INVERTOR_FILTERS,
  SET_SWITCH_FILTERS,
  SET_AUTOWIRE_FILTERS,
  SET_BUILDING_ITEM
} from 'store/types';

export const SetBuildingItem = (item) => {
  return (dispatch) => {
    dispatch({
      type: SET_BUILDING_ITEM,
      payload: {
        key: item['key'],
        value: item['value']
      }
    })
  }
}

export const SetPanelFilters = (res) => {
  return (dispatch) => {
    dispatch({
      type: SET_PANEL_FILTERS,
      payload: {
        filters: res
      }
    })
  }
}

export const SetBatteryFilters = (res) => {
  return (dispatch) => {
    dispatch({
      type: SET_BATTERY_FILTERS,
      payload: {
        filters: res
      }
    })
  }
}

export const SetInvertorFilters = (res) => {
  return (dispatch) => {
    dispatch({
      type: SET_INVERTOR_FILTERS,
      payload: {
        filters: res
      }
    })
  }
}

export const SetSwitchFilters = (res) => {
  return (dispatch) => {
    dispatch({
      type: SET_SWITCH_FILTERS,
      payload: {
        filters: res
      }
    })
  }
}

export const SetAutowireFilters = (res) => {
  return (dispatch) => {
    dispatch({
      type: SET_AUTOWIRE_FILTERS,
      payload: {
        filters: res
      }
    })
  }
}
