import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
// import AuthRoute from './index'

import {
  MyArrays,
  DefaultLayout,
  VirtualBuilding,
  VirtualBuildingProduct,
  VirtualBuildingProductFilter,
  Cart,
  Network,
  Profile,
  Login,
  Register,
  ForgotPassword
} from "components";

const LandingComponent = () => {
  const token = window.sessionStorage.getItem('token')
  if (!token) {
    return (<Redirect to="/login" />)
  }
  return (<Redirect to="/app/virtual_building" />)
}

export const Routes = (props) => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/forgot_password" component={ForgotPassword} />
    <DefaultLayout>
      <Route exact path="/" component={LandingComponent} />
      <Route path="/app/virtual_building" component={VirtualBuilding} />
      <Route path="/app/product/:name" component={VirtualBuildingProduct} />
      <Route path="/app/:name/filter" component={VirtualBuildingProductFilter} />
      <Route path="/app/arrays" component={MyArrays} />
      <Route path="/app/cart" component={Cart} />
      <Route path="/app/network" component={Network} />
      <Route path="/app/profile" component={Profile} />
    </DefaultLayout>
  </Switch>
)

export default Routes
