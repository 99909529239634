import React from "react";
import { useHistory } from "react-router-dom";

const ForgotPassword = (props) => {
  const history = useHistory();

  const redirectTo = (pathname) => {
    history.push(pathname);
  }

  return (
    <div className="login-wrapper" style={{maxWidth: "500px", margin: "0px auto"}}>
      <div className="d-flex justify-content-between align-items-center p-2 bg-primary">
        <div className="p-2">
          Forgot password
        </div>
      </div>
      <div className="container py-3">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" className="form-control" id="email" placeholder="Ex: nich@gmail.com" />
        </div>
        <button type="submit" className="btn btn-outline-primary btn-sm mt-3" onClick={e => redirectTo('login')}>Send verification email</button> <br/><br/>
        <button type="" className="btn btn-link text-primary" onClick={e => redirectTo('login')}>Login</button><br/>
        <button type="" className="btn btn-link text-primary" onClick={e => redirectTo('register')}>Register</button>
      </div>
    </div>
  )
}

export default ForgotPassword;
